


























import {Component, Prop, Vue} from 'vue-property-decorator'
import CircularLoader from '@/components/common/Loading/CircularLoader.vue'
@Component({
    components: {
        CircularLoader
    }
})

export default class Communication extends Vue{
    private loading:boolean = true
}

